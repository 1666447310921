// Styles
import '@mdi/font/css/materialdesignicons.css'
// import 'vuetify/styles'
import colors from 'vuetify/lib/util/colors'
// Vuetify
import { createVuetify } from 'vuetify'
import { VNumberInput } from 'vuetify/labs/VNumberInput'
export default createVuetify(
  {
    components: {
      VNumberInput,
    },
    theme: {
      themes: {
        light: {
          dark: false,
          colors: {
            primary: colors.blue.base, // #E53935
          
          }
        },
      },
    },
  }
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
